// 
// main.js
//


var body = document.body;

var navbar = document.getElementById("skol-navbar");

function onscrollNavbar() {
  if (window.pageYOffset >= 100) {
      navbar.classList.add("on-scroll");
      body.classList.add("on-scroll");
  } else {
      navbar.classList.remove("on-scroll");
      body.classList.remove("on-scroll");
  }
}

window.onscroll = function() {
    onscrollNavbar();
};

window.onload  = function() {
    onscrollNavbar();
}